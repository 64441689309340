import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import queryKeys from "../utils/queryKeys";
import { useAppContext } from "./useAppContext";
import { useParams } from "react-router-dom";

export const useActivities = () => {
  const { apiServices, permission, user } = useAppContext("activities");
  const { id } = useParams();

  const {
    isLoading: activitiesLoading,
    data: activities,
    refetch: refetchActivities,
  } = useQuery([queryKeys.GET_ACTIVITIES], apiServices.getActivities, {
    // select: apiServices.formatData,
    select: (data) => {
      // console.log({ dat: data?.data });
      // return data?.data;
      return data?.data?.map((obj, index) => {
        const newObj = { ...obj };
        newObj.new_id = index + 1;
        return newObj;
      })
    },
    onError: apiServices.errorHandler,
  });

  // return apiServices.formatData(data)?.map((obj, index) => {
  //   const newObj = { ...obj };
  //   newObj.new_id = index + 1;
  //   return newObj;
  // });

  // const { isLoading: subjectDataLoading, data: subjectData } = useQuery(
  //   [queryKeys.GET_SUBJECTS, id],
  //   () => apiServices.getSubject(id),
  //   {
  //     enabled: !!id,
  //     select: apiServices.formatSingleData,
  //     onError: apiServices.errorHandler,
  //   }
  // );

  const { isLoading: addActivitiesLoading, mutate: addActivities } =
    useMutation(apiServices.addActivities, {
      onSuccess() {
        toast.success("Activity has been created successfully");
      },
      onError: apiServices.errorHandler,
    });

  // const {
  //   isLoading: addSubjectsToClassLoading,
  //   mutate: assignSubjectsToClass,
  // } = useMutation(apiServices.assignSubjectsToClass, {
  //   onSuccess() {
  //     toast.success("Subject has been assigned successfully");
  //   },
  //   onError: apiServices.errorHandler,
  // });

  const { isLoading: deleteActivityLoading, mutate: deleteActivity } =
    useMutation(apiServices.deleteActivities, {
      onSuccess() {
        toast.success("Activity has been deleted successfully");
        refetchActivities();
      },
      onError: apiServices.errorHandler,
    });

  // const { isLoading: updateSubjectLoading, mutate: updateSubject } =
  //   useMutation(apiServices.updateSubject, {
  //     onSuccess() {
  //       toast.success("Subject has been updated successfully");
  //     },
  //     onError: apiServices.errorHandler,
  //   });

  const isLoading =
    activitiesLoading || addActivitiesLoading || deleteActivityLoading;

  // console.log({ id, subjectData });

  return {
    isLoading,
    activities,
    addActivities,
    deleteActivity,
    permission,
    isEdit: !!id,
    user,
  };
};

import React from "react";
import PageView from "../../../components/views/table-view";
import { useActivities } from "../../../hooks/useActivities";
// import { useSubject } from "../../../hooks/useSubjects";

const ExtraCurricular = () => {
  const {
    isLoading,
    activities,
    addActivities,
    deleteActivity,
    permission,
    isEdit,
    user,
  } = useActivities();
  // const { subjects, permission, isLoading, deleteSubject, user } = useSubject();

  // const checkForExtraButton = () => {
  //   if (
  //     user?.is_preschool === "false" &&
  //     user?.designation_name === "Admin" &&
  //     user?.department === "Admin"
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // console.log({ user, cb: checkForExtraButton() });
  console.log({ activities });

  return (
    <PageView
      canCreate={permission?.create}
      // extraButton={checkForExtraButton()}
      // extraLink={`/app/classes`}
      // extraButtonTitle="Assign to Class"
      // rowHasUpdate={permission?.update}
      rowHasDelete={permission?.delete}
      onDelete={deleteActivity}
      isLoading={isLoading}
      columns={[
        {
          Header: "s/n",
          accessor: "new_id",
        },
        {
          Header: "Activity",
          accessor: "name",
        },
        // {
        //   Header: "Class",
        //   accessor: "class_name",
        // },
      ]}
      data={activities}
    />
  );
};

export default ExtraCurricular;

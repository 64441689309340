import React, { useEffect, useState } from "react";
import PageSheet from "../../../../../components/common/page-sheet";
import { useResults } from "../../../../../hooks/useResults";
import { useAppContext } from "../../../../../hooks/useAppContext";
import Button from "../../../../../components/buttons/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPrint } from "@fortawesome/free-solid-svg-icons";
import StudentsResults from "../../../../../components/common/students-results";
import ResultHeader from "../../../../../components/common/result-header";
import { useStudent } from "../../../../../hooks/useStudent";
import { calculateAge, calculateAgeWithMonths } from "../../constant";

const SubjectTable = ({ subject, header = [], topics = [] }) => {
  return (
    <div
      className="subject-table"
      style={{
        width: "100%",
        // textAlign: "justify",
        // padding: "0px 10px",
        // fontStyle: "italic"
      }}
    >
      <div className="table-subhead" style={{ width: `100%` }}>
        <h4
          style={{
            fontSize: "18px",
            // textAlign: "justify",
            // padding: "0px 10px",
            // fontStyle: "italic"
          }}
        >
          {subject}
        </h4>
      </div>
      <div className="subject-main-table" style={{ width: `100%` }}>
        <div>
          <div className="table-data">
            <h4
              style={{
                fontSize: "18px",
                // textAlign: "justify",
                // padding: "0px 10px",
                // fontStyle: "italic"
              }}
            >
              {subject}
            </h4>
          </div>
          <div className="score-part">
            {header.map((h) => (
              <div
                key={h}
                className="table-data subject-head"
                style={{ width: `${100 / header.length}%`, padding: "3px 3px" }}
              >
                {h === "Archieved" ? (
                  <h4
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      // textAlign: "justify",
                      // padding: "0px 10px",
                      // fontStyle: "italic"
                    }}
                  >
                    ACHIEVED
                  </h4>
                ) : (
                  <h4
                    style={{
                      fontSize: "14px",
                      lineHeight: "16px",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      // textAlign: "justify",
                      // padding: "0px 10px",
                      // fontStyle: "italic"
                    }}
                  >
                    {h}
                  </h4>
                )}
              </div>
            ))}
          </div>
        </div>
        {topics.map((t) => (
          <div key={t.topic}>
            <div className="table-data">
              <h4
                style={{
                  fontSize: "15px",
                  lineHeight: "16px",
                  // textAlign: "justify",
                  // padding: "0px 10px",
                  // fontStyle: "italic"
                }}
              >
                {t.topic}
              </h4>
            </div>
            <div className="score-part">
              {header.map((h) => (
                <div
                  key={h}
                  className="table-data"
                  style={{ width: `${100 / header.length}%` }}
                >
                  {t.score === h && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      color="green"
                      style={{ fontSize: "24px" }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PreSchoolResult = () => {
  const { user } = useAppContext("results");
  const {
    idWithComputedResult,
    isLoading,
    setStudentData,
    pdfExportComponent,
    handlePrint,
    studentByClassAndSession,
    studentData,
    academicDate,
    preSchoolCompiledResults,
  } = useResults();

  const [newStudentData, setNewStudentData] = useState({});

  const { studentByClass2 } = useStudent();

  const result =
    preSchoolCompiledResults?.find(
      ({ student_id }) => student_id === studentData.id
    ) ?? null;

  // console.log({
  //   result,
  //   studentData,
  //   db: calculateAge(studentData?.dob),
  //   db2: calculateAgeWithMonths(studentData?.dob),
  // });

  useEffect(() => {
    setNewStudentData(studentData);
  }, []);

  console.log({ result });

  return (
    <div className="results-sheet">
      {user?.designation_name !== "Student" && (
        <StudentsResults
          studentByClassAndSession={studentByClass2}
          onProfileSelect={(x) => setStudentData(x)}
          isLoading={isLoading}
          studentData={studentData}
          idWithComputedResult={idWithComputedResult}
        />
      )}
      <PageSheet>
        <div className="mb-3">
          <Button
            onClick={() => {
              if (pdfExportComponent.current) {
                handlePrint();
              }
            }}
          >
            <FontAwesomeIcon icon={faPrint} /> Print
          </Button>
        </div>

        <div
          ref={pdfExportComponent}
          className="first-level-results-sheet preschool"
        >
          <ResultHeader user={user} />
          <div className="preschool-result-table">
            <div className="table-head">
              <h3
                style={{
                  fontSize: "18px",
                  lineHeight: "16px",
                  // textAlign: "justify",
                  // padding: "0px 10px",
                  // fontStyle: "italic"
                }}
              >
                {result?.session} Academic Sessions
              </h3>
            </div>
            <div
              className="student-creds"
              style={{
                fontSize: "15px",
                lineHeight: "16px",
                // textAlign: "justify",
                // padding: "0px 10px",
                // fontStyle: "italic"
              }}
            >
              <div>
                <div className="table-data">
                  <h4
                    style={{
                      fontSize: "15px",
                      lineHeight: "16px",
                      // textAlign: "justify",
                      // padding: "0px 10px",
                      // fontStyle: "italic"
                    }}
                  >
                    Name: {result?.student_fullname}
                  </h4>
                </div>
                <div className="table-data">
                  <h4
                    style={{
                      fontSize: "15px",
                      lineHeight: "16px",
                      // textAlign: "justify",
                      // padding: "0px 10px",
                      // fontStyle: "italic"
                    }}
                  >
                    Admission No.: {result?.admission_number}
                  </h4>
                </div>
                <div className="table-data">
                  <h4
                    style={{
                      fontSize: "15px",
                      lineHeight: "16px",
                      // textAlign: "justify",
                      // padding: "0px 10px",
                      // fontStyle: "italic"
                    }}
                  >
                    {result?.term}
                  </h4>
                </div>
              </div>
              <div>
                <div className="table-data">
                  {/* <h4>Chronological Age: 4 years 7 month</h4> */}
                  {/* <h4>Age: {studentData?.age} year(s)</h4> */}
                  <h4
                    style={{
                      fontSize: "15px",
                      lineHeight: "16px",
                      // textAlign: "justify",
                      // padding: "0px 10px",
                      // fontStyle: "italic"
                    }}
                  >
                    Age: {calculateAgeWithMonths(studentData?.dob)}
                  </h4>
                  {/* {result?.session && <h4>{result?.session} SESSION</h4>} */}
                </div>
                <div className="table-data">
                  <h4
                    style={{
                      fontSize: "15px",
                      lineHeight: "16px",
                      // textAlign: "justify",
                      // padding: "0px 10px",
                      // fontStyle: "italic"
                    }}
                  >
                    School Section: Nursery
                  </h4>
                </div>
                <div className="table-data">
                  <h4
                    style={{
                      fontSize: "15px",
                      lineHeight: "16px",
                      // textAlign: "justify",
                      // padding: "0px 10px",
                      // fontStyle: "italic"
                    }}
                  >
                    Class: {result?.class_name}
                  </h4>
                </div>
              </div>
            </div>
            {/* <div className="table-head">
              <h3>Attendance Record</h3>
            </div>
            <div className="attendance">
              <div>
                <div className="table-subhead">
                  <h4>Number of Times School Opened</h4>
                </div>
                <div className="table-data">
                  <h4>{result?.school_opened}</h4>
                </div>
              </div>
              <div>
                <div className="table-subhead">
                  <h4>Number of Times Present</h4>
                </div>
                <div className="table-data">
                  <h4>{result?.times_present}</h4>
                </div>
              </div>
              <div>
                <div className="table-subhead">
                  <h4>Number of Times Absent</h4>
                </div>
                <div className="table-data">
                  <h4>{result?.times_absent}</h4>
                </div>
              </div>
              <div>
                <div className="table-subhead">
                  <h4>This Term Ends</h4>
                </div>
                <div className="table-data">
                  <h4>{academicDate?.session_ends}</h4>
                </div>
              </div>
              <div>
                <div className="table-subhead">
                  <h4>Next Term Begins</h4>
                </div>
                <div className="table-data">
                  <h4>{academicDate?.session_resumes}</h4>
                </div>
              </div>
            </div> */}
            <div className="table-head">
              <h3
                style={{
                  fontSize: "18px",
                  lineHeight: "16px",
                  // textAlign: "justify",
                  // padding: "0px 10px",
                  // fontStyle: "italic"
                }}
              >
                Evaluation Report
              </h3>
            </div>
            <div className="reports">
              <div>
                {result?.evaluation_report
                  ?.slice(0, Math.round(result?.evaluation_report?.length / 2))
                  ?.map((subject, key) => (
                    <SubjectTable
                      key={key}
                      subject={subject.subject}
                      header={[
                        "Needs Improvement",
                        "Fair",
                        "Good",
                        "Excellent",
                      ]}
                      topics={subject.topic}
                    />
                  ))}
              </div>
              <div>
                {result?.evaluation_report
                  ?.slice(Math.round(result?.evaluation_report?.length / 2))
                  ?.map((subject, key) => (
                    <SubjectTable
                      key={key}
                      subject={subject.subject}
                      header={[
                        "Needs Improvement",
                        "Fair",
                        "Good",
                        "Excellent",
                      ]}
                      topics={subject.topic}
                    />
                  ))}
              </div>
            </div>
            <div className="table-head">
              <h3
                style={{
                  fontSize: "18px",
                  lineHeight: "16px",
                  // textAlign: "justify",
                  // padding: "0px 10px",
                  // fontStyle: "italic"
                }}
              >
                Cognitive Report
              </h3>
            </div>
            <div className="reports">
              <div>
                {result?.cognitive_development
                  ?.slice(0, Math.round(result?.evaluation_report?.length / 2))
                  ?.map((subject, key) => (
                    <SubjectTable
                      key={key}
                      subject={subject.subject}
                      header={[
                        "Work in Progress",
                        "Needs Reinforcement",
                        "Archieved",
                      ]}
                      topics={subject.topic}
                    />
                  ))}
              </div>
              <div>
                {result?.cognitive_development
                  ?.slice(Math.round(result?.evaluation_report?.length / 2))
                  ?.map((subject, key) => (
                    <SubjectTable
                      key={key}
                      subject={subject.subject}
                      header={[
                        "Work in Progress",
                        "Needs Reinforcement",
                        "Archieved",
                      ]}
                      topics={subject.topic}
                    />
                  ))}
              </div>
            </div>
            <div className="table-head">
              <h3
                style={{
                  fontSize: "18px",
                  lineHeight: "16px",
                  // textAlign: "justify",
                  // padding: "0px 10px",
                  // fontStyle: "italic"
                }}
              >
                Class Teacher's General Comment
              </h3>
            </div>
            {/* <div className="comment">
              <h4>{result?.teacher_comment}</h4>
              <div className="signature">
                <div>
                  {result?.teacher_signature && (
                    <img
                      src={result?.teacher_signature}
                      width="200px"
                      // height="fit"
                      alt=""
                    />
                  )}
                  <div className="line" />
                  <h3>{result?.teacher_fullname}</h3>
                </div>
              </div>
            </div> */}
            <div className="comment">
              <h4
                className=""
                style={{
                  fontSize: "19px",
                  lineHeight: "22px",
                  textAlign: "justify",
                  padding: "0px 10px",
                  fontStyle: "italic",
                }}
              >
                {result?.teacher_comment}
              </h4>
              {result?.teachers?.length > 0 && (
                <div className="d-flex px-5 justify-content-between mt-5">
                  <div>
                    {result?.teachers[0]?.signature && (
                      <div>
                        <img
                          src={result?.teachers[0]?.signature}
                          alt=""
                          style={{
                            width: "150px", // Set the desired width
                            height: "80px", // Set the desired height
                            objectFit: "cover", // You can use 'cover', 'contain', 'fill', etc.
                          }}
                          // height="200px"
                        />
                      </div>
                    )}
                    <div className="line" style={{ marginTop: "18px" }} />
                    <h3 style={{ fontSize: "18px" }}>
                      {result?.teachers[0]?.name}
                    </h3>
                  </div>
                  <div>
                    {result?.teachers[1]?.signature && (
                      <div>
                        <img
                          src={result?.teachers[1]?.signature}
                          alt=""
                          style={{
                            width: "100px", // Set the desired width
                            height: "80px", // Set the desired height
                            objectFit: "cover", // You can use 'cover', 'contain', 'fill', etc.
                          }}
                        />
                      </div>
                    )}
                    <div className="line" style={{ marginTop: "18px" }} />
                    <h3 style={{ fontSize: "18px" }}>
                      {result?.teachers[1]?.name}
                    </h3>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="table-head">
              <h3>Head of Nursery's Comment</h3>
            </div>
            <div className="comment">
              <h4>{result?.hos_comment}</h4>
              <div className="signature">
                <div>
                  {result?.hos_signature && (
                    <img
                      src={result?.hos_signature}
                      alt=""
                      width="200px"
                      height="200px"
                    />
                  )}
                  <div className="line" />
                  <h3>{result?.hos_fullname}</h3>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </PageSheet>
    </div>
  );
};

export default PreSchoolResult;
